import Component from '../lib/Component'
import Swiper from 'swiper'
import ComponentArray from "../lib/ComponentArray"
import KoinuSliderButton from "./KoinuSliderButton"

export default class KoinuSlider extends Component {
    buttons:ComponentArray
    sliderMain:any
    mount() {
        this.sliderMain =  new Swiper(this.element, {
            effect: 'fade',
            autoHeight: true,
            fadeEffect: {
                crossFade: false,
            },
            spaceBetween: 0,
            slideChange: () => {
                let sliderIndex;
                sliderIndex = this.sliderMain.realIndex +1;


            },
        });

        this.buttons = new ComponentArray(KoinuSliderButton, () => document.querySelectorAll('.js-koinu-slider-thumbnail'))

        this.buttons.map((_c,index) => {
            const c = <KoinuSliderButton>_c
            c.setClick(this,index)
            if(index == 0){
                c.addClass()
            }
        })
    }

    resetClass() {
        this.buttons.map((_c,index) => {
            const c = <KoinuSliderButton>_c
            c.resetClass();

        })
    }

}


