import Component from '../lib/Component'
import Swiper from 'swiper'

export default class KoinuSliderButton extends Component {
    slider:any
    mount() {

    }


    setClick(Slider:any,SliderIndex:number){
        this.on('click',(event)=>{
            Slider.sliderMain.slideTo(SliderIndex);
            Slider.resetClass();
            this.element.classList.add('is-active');
        })
    }

    resetClass(){
        this.element.classList.remove('is-active');
    }

    addClass(){
        this.element.classList.add('is-active');
    }

}


